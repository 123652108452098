<template>
  <Layout>
    <template v-slot:label>
      {{ row.label }}
    </template>

    <template v-slot:extra>
      {{ row.extra }}
    </template>

    <template v-slot:input>
      <input
        v-model="model"
        type="checkbox"
        class="text-lg text-purple-500 form-checkbox mt-1"
      >
    </template>
  </Layout>
</template>
<script>
const Layout = () => import('./Layout')

export default {
  name: 'CheckboxInput',

  components: {
    Layout
  },

  props: ['row', 'rowkey', 'keypath'],

  data () {
    return {
      model: this.row.value
    }
  }
}

</script>
